import React, { ReactElement } from "react";

import Image from "next/image";
export default function HomeWarrantyBasics(): ReactElement {
    return (
        <section className="flex flex-col lg:flex-row  lg:gap-16 gap-8 lg:mt-16 mt-8 ">
            <div className="max-w-[719px] mx-auto lg:mx-0 ">
                <div className="flex gap-4">
                    <span className="block w-[6px] h-[42px] bg-[#F6CEBE]"></span>
                    <h2 className="font-bold lg:text-3xl text-xl lg:leading-[45px]  ">
                        Home Warranty Basics
                    </h2>
                </div>

                <p className="mt-3 font-light lg:text-lg  text-base lg:leading-8 text-[#505050]">
                    A home warranty is a comprehensive plan designed to cover
                    the costs of repairs or replacements for your household
                    appliances and systems. It operates similarly to a yearly
                    subscription and requires an annual renewal. However, the
                    specific coverage and terms can vary depending on the chosen
                    plan, offering flexibility to homeowners.
                </p>
            </div>
            <div className="w-[268px] h-[130px] md:w-[388px] md:min-w-[388px] md:h-[189px]  relative  mx-auto lg:mx-0 ">
                <Image
                    src={
                        "/homewarranty.center/assets/images/homeWarrantyBasicsImg.png"
                    }
                    alt="Image"
                    layout="fill"
                />
            </div>
        </section>
    );
}
